import * as React from 'react';
import { PageProps } from 'gatsby';

import PresentationLayout from '~/components/layout/Presentation';

export default function typescriptOt({ location: pLocation }: PageProps) {
  return (
    <PresentationLayout pathname={pLocation.pathname}>
      <div className="slides">
        <section>
          <div>
            <img
              src="/img/presentation/typescript/logo.png"
              alt="logo"
              style={{ width: '50%', margin: '0 auto', background: 'none', border: 'none' }}
            />
            <p style={{ margin: '20px 0 0 0' }}>기초부터 시작하는 타입스크립트 강의</p>
            <p style={{ margin: '20px 0 0 0' }}>OT</p>
          </div>
          <aside className="notes"></aside>
        </section>

        <section>
          <div>
            <h2>강사 소개</h2>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  display: 'inline-block',
                  width: '200px',
                  height: '200px',
                  marginRight: '40px',
                  background: 'url(/img/resume/profile.jpg) 0 0 / 200px',
                  borderRadius: '200px'
                }}
              />
              <div style={{ display: 'inline-block' }}>
                <ul>
                  <li>NHN벅스 근무</li>
                  <li>카카오페이 근무</li>
                  <li>라인플러스 근무</li>
                  <li>두나무 &gt; Levvels 재직 (자회사)</li>
                </ul>
              </div>
            </div>
            <aside className="notes">
              NHN벅스, 카카오페이, 라인플러스를 거쳐 현재 두나무에 재직중이고, 타입스크립트를 사용한지는 4년 이상이 된
              것 같습니다. 여러 프로젝트를 거치며 타입스크립트를 사용하였고 여러 환경의 다양한 문제를 접해본 것
              같습니다. 이에 대한 경험들을 여러분과 나누고 싶어 강의를 만들게 되었습니다.
            </aside>
          </div>
        </section>

        <section>
          <div>
            <h2>강의 대상</h2>
            <ul>
              <li>자바스크립트의 기본적인 사용 방법을 알고있는 개발자</li>
              <li>
                타입스크립트가 처음이거나, 타입스크립트 문서에 있는 내용을 모두 파악하지 못하고 기본기만 사용하고 있는
                개발자
              </li>
              <li>타입스크립트를 기초만 안다거나, 아직 실무에 적용해본적이 없는 개발자</li>
              <li>
                항상 CRA와 같은 bootstrap 툴만 이용하여 개발은 할 줄 알지만 번들러(bundler)나 기타 타입스크립트 환경
                설정을 해보지 못한 개발자
              </li>
            </ul>
          </div>
          <aside className="notes">
            그동안 타입스크립트에도 많은 변화가 있었는데, 이 강의는 자바스크립트에 대한 경험이 있는 웹 개발자를 대상으로
            하려고 합니다. 물론 Node도 이에 해당합니다. 타입스크립트가 처음이거나, 사용은 해봤으나 문서에 있는 내용은
            모두 파악해 레벨업하고 싶은분, 타입스크립트의 여러 환경설정을 파악하지 못하고 Create React App처럼
            bootstraping 툴만 사용해보셨던 분 모두 추천드립니다.
          </aside>
        </section>

        <section>
          <h2>강의 목표</h2>
          <ul>
            <li>복잡한 프로젝트에서 자바스크립트를 썼을 때의 문제를 줄일 수 있다.</li>
            <li>모호한 이름의 변수가 어떤 타입의 값을 받는지 특정 가능</li>
            <li>협업하며 불어난 코드 정리가 쉬워진다.</li>
            <li>타입스크립트를 기존에 사용하던 프로젝트에 적용할 수 있다.</li>
            <li>바닐라 자바스크립트 프로젝트에 타입스크립트 적용 예제를 통해 쉽게 따라하며 배울 수 있다.</li>
            <li>기존에 만들어놓은 자바스크립트 코드도 쉽게 타입스크립트 프로젝트로 바꿀 수 있다.</li>
          </ul>
          <aside className="notes">
            자연스레 자바스크립트만 쓰고 계셨을 분들이 많습니다. 20년도 말에 라인플러스에 입사해서 제일 먼저 했던 일도
            타입스크립트를 전파하고 프로젝트에 적용했던 일입니다. 새로운 기술은 적용하기 전까지 설득이 힘들지만 막상
            적용하고 나면 많은 이들에게 큰 호응을 받는 일이 종종 있는데, 타입스크립트가 그러했습니다. 따라서 위와 같은
            강점들을 목표로 여러분께 당당하게 반드시 타입스크립트를 써야하고, 배워보시라고 말씀드립니다.
          </aside>
        </section>

        <section>
          <h2>강의 안내</h2>
          <ul>
            <li>타입스크립트를 왜 써야하는지</li>
            <li>
              기초 개념 설명
              <ul>
                <li>String, Number, null, undefined, Array... 어떻게 사용해야 하나?</li>
                <li>Object들의 수많은 property들. 어떻게 type을 지정해줄까</li>
              </ul>
            </li>
            <li>
              고급 개념 설명
              <ul>
                <li>여러 Object들의 타입을 합치고 나누고 더하고 빼고!</li>
              </ul>
            </li>
            <li>tsconfig의 수많은 옵션들</li>
            <li>
              bundler
              <ul>
                <li>bundler와 transpiler, polyfill... 이게 뭘까?</li>
                <li>
                  타입스크립트를 bundler와 함께 사용해보자.
                  <ul>
                    <li>vite, webpack, parcel, rollup과 함께하는 코딩 예제</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <aside className="notes">
            여러분께는 포괄적인 강의 대상을 말씀드린만큼 기초개념, 고급개념, tsconfig 옵션들, bundler와 함께 사용하는
            방법들까지 여러 대상들을 위한 모든 기능을 다뤄볼껍니다. 강의 자료는 typescript docs를 기반으로 하기 때문에
            "여기서 기초만 다루면 결국 디테일하게 배우려면 docs를 봐야하는거 아니야?" 라는 걱정을 하실 필요 없습니다.
            최대한 많은것을 자세히 알려드리도록 해볼게요.
          </aside>
        </section>

        <section>
          <h1>끝</h1>
          <div>감사합니다.</div>
        </section>
      </div>
    </PresentationLayout>
  );
}
